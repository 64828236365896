<template>
    <div data-test="complaintCommentsWrapper" class="comments fhf">
        <div class="fill" ref="comments">
            <div data-test="complaintCommentsContentNo" v-if="comments.length === 0">
                No comments
            </div>
            <div data-test="complaintCommentsContent"
                v-for="(note, key) in comments"
                :key="key"
                class="comments__line"
            >
                <strong>{{note.author}}:</strong> {{note.note}} <small class="comments__date">{{note.dateCreated | asDatetime}}</small>
            </div>
        </div>
        <div class="mt-2" >
            <label>Add a comment</label>
            <mercur-textarea data-test="complaintCommentsContentAdd" v-model="commentValue" @keydown.enter="handleCmdEnter($event)">
            </mercur-textarea>
        </div>
        <div>
            <div data-test="complaintCommentsContentStatus" v-if="recipient !== 'merchant'">
                <label>Update complaint status</label>
                <mercur-select data-test="complaintCommentsContentStatusSelect" :disabled="!actionsAllowed" v-model="complaintStatus">
                    <option v-for="(title, value) in allowedStatusses" :value="value" :key="value">{{ title }}</option>
                </mercur-select>
            </div>
        </div>
        <mercur-button data-test="complaintCommentsContentSend" class="btn btn-yellow btn-raised m-1" :disabled="!canSend" @click="addCommentSave()">Send {{isMerchantTab ? 'back to merchant' : ''}}</mercur-button>
    </div>
</template>
<script>
import moment from 'moment'

export default {
    name: 'ComplaintComments',
    props: {
        complaint: {
            required: true,
            type: Object,
        },
        commentFilters: {
            required: true,
            type: Object,
        },
        recipient: {
            required: true,
            type: String,
        },
    },
    data () {
        return {
            complaintStatus: null,
            commentValue: null,
        }
    },
    watch: {
        comments: {
            handler () {
                this.$nextTick(() => {
                    const scrollInterval = setInterval(() => {
                        this.scrollCommentsToBottom()
                    }, 100)
                    setInterval(() => {
                        clearInterval(scrollInterval)
                    }, 500)
                })
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        canSend () {
            if (this.complaintStatus === 'REJECTED' && !this.commentValue) {
                return false
            }

            if (this.isMerchantTab && !this.commentValue) {
                return false
            }

            return true
        },
        isMerchantTab () {
            return this.recipient === 'merchant'
        },
        isSupplierTab () {
            return this.recipient === 'supplier'
        },
        actionsAllowed () {
            if (!this.complaint) {
                return false
            }
            return this.complaint.complaintStatus === 'AWAITING_SUPPLIER' || this.complaint.complaintStatus === 'REJECTED'
        },
        comments () {
            if (!this.complaint) {
                return []
            }

            const mergedNotes = []
            Object.entries(this.commentFilters).forEach(([field, { recipients, author }]) => {
                this.complaint[field].forEach(note => {
                    if (note.recipients && note.recipients.some(recipient => recipients.map(listedRecipient => listedRecipient.toUpperCase()).includes(recipient.toUpperCase()))) {
                        mergedNotes.push({
                            ...note,
                            author,
                        })
                    }
                })
            })
            mergedNotes.sort((a, b) => moment(a.dateCreated).valueOf() - moment(b.dateCreated).valueOf())
            return mergedNotes
        },
        allowedStatusses () {
            return {
                AWAITING_SUPPLIER: 'Awaiting supplier',
                APPROVED: 'Approved',
                REJECTED: 'Rejected',
            }
        },
    },

    methods: {
        handleCmdEnter (e) {
            if ((e.metaKey || e.ctrlKey)) {
                this.addCommentSave()
            }
        },
        addCommentSave () {
            const complaint = JSON.parse(JSON.stringify(this.complaint))
            if (this.commentValue) {
                if (this.isSupplier) {
                    complaint.supplierComplaintNotes.push({
                        note: this.commentValue,
                        uploadedBy: this.$store.state.auth.user.accountId,
                        recipients: ['VIRTUAL_SUPPLIER'],
                    })
                } else {
                    complaint.virtualSupplierComplaintNotes.push({
                        note: this.commentValue,
                        uploadedBy: this.$store.state.auth.user.accountId,
                        recipients: [this.recipient.toUpperCase()],
                    })
                }
            }
            complaint.complaintStatus = this.complaintStatus

            this.$emit('save-complaint-data', {
                complaint,
                method: this.isMerchantTab ? 'save-and-send-to-merchant' : 'save',
            })
            this.commentValue = null
            this.$nextTick(() => {
                this.scrollCommentsToBottom()
            })
        },
        scrollCommentsToBottom () {
            if (!this.$refs.comments) {
                return
            }
            this.$refs.comments.scrollTop = this.$refs.comments.scrollHeight
        },
    },

    created () {
        this.complaintStatus = this.complaint.complaintStatus
    },
}

</script>

<style lang="scss" scoped>
    .comments {
        margin-top: auto;

        &__line {
            margin-right: 3px;
            margin-bottom: 5px;
            margin-top: 5px;
            border-bottom: 1px solid #dedede;
            padding-bottom: 20px;
            padding-top: 10px;
            overflow: hidden;
            position: relative;
            white-space: pre-line;
            font-size: 12px;
            line-height: 1.2em;
        }
        &__date {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
</style>
