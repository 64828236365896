import { render, staticRenderFns } from "./ComplaintDetail.vue?vue&type=template&id=51db1724&scoped=true&"
import script from "./ComplaintDetail.vue?vue&type=script&lang=js&"
export * from "./ComplaintDetail.vue?vue&type=script&lang=js&"
import style0 from "./ComplaintDetail.vue?vue&type=style&index=0&id=51db1724&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51db1724",
  null
  
)

export default component.exports