<template>
    <div v-if="isAllowedTo('SupplierCentral/getComplaintsBySupplierId')" class="sidebar-detail">
        <div class="sidebar-detail__header" >
            <div class="p-2 d-flex align-items-center">
                    <mercur-button class="btn btn-icon" :to="parentRoute">
                        <i class="fas fa-times"></i>
                    </mercur-button>
                    <span class="ml-2">{{orderName}}</span>
            </div>
        </div>
        <div class="sidebar-detail__content full-height-layout">
            <div v-if="loading" class="sidebar-detail__spinner" >
                <mercur-spinner
                    :size="2"
                ></mercur-spinner>
            </div>
            <div v-else-if="complaint" class="fhf">
                <dl class="fill complaint-details">
                    <template v-for="(value, key) in complaintDetailsTable">
                        <dt :key="`${key}_key`">{{key | beautify}}:</dt>
                        <dd :key="`${key}_value`">
                            <template v-if="value === null || value === ''">-</template>
                            <template v-else-if="key === 'dateUpdated'">{{value | asDatetime}}</template>
                            <template v-else-if="typeof value === 'object'">
                                <dl>
                                    <template v-for="(subValue, subKey) in value">
                                        <dt :key="`${subKey}_key`">{{subKey}}</dt>
                                        <dd :key="`${subKey}_value`">{{subValue}}</dd>
                                    </template>
                                </dl>
                            </template>
                            <template v-else-if="key === 'Costs' && !isSupplier">{{value}} <a @click="editSupplierComplaintCostsDialog = true"><u>edit</u></a></template>
                            <template v-else-if="key === 'Complaint description' && !isSupplier">{{value}} <a @click="editDescriptionDialog = true"><u>edit</u></a></template>
                            <template v-else>{{value}}</template>
                        </dd>
                    </template>
                    <dt>Attachments:</dt>
                    <dd>
                        <a v-if="complaint.complaintAttachments && complaint.complaintAttachments.length" @click="attachmentsDialogActive = true">
                            View attachments
                        </a>
                        <em v-else>None</em>
                    </dd>
                </dl>
                <center>Comments</center>
                <div class="complaint-comments">
                    <mercur-tabs :is-full="true" class="mb-4 p-4">
                        <mercur-tab class="pt-2" v-for="(commentFilters, commentsSectionName) in commentSections" :key="commentsSectionName" :id="`tab-comments_${commentsSectionName}`" :title="commentsSectionName.toUpperCase()">
                            <complaint-comments
                                :complaint="complaint"
                                :commentFilters="commentFilters"
                                :recipient="commentsSectionName"
                                @save-complaint-data="saveComplaintData($event)"
                            ></complaint-comments>
                        </mercur-tab>
                    </mercur-tabs>
                </div>
                <mercur-dialog :is-open.sync="attachmentsDialogActive">
                    <div slot="header">
                        <h3>Attachments</h3>
                    </div>
                    <div>
                        <mercur-item
                            :disabled="downloadLoading"
                            v-for="(attachment, key) in complaint.complaintAttachments.filter(({shareWithSupplier}) => shareWithSupplier)"
                            :key="key">
                            <div class="d-flex"
                                @click="downloadAttachment(attachment)">
                                <i class="fas fa-paperclip" />
                                <span class="text-truncate">{{attachment.fileName}}</span>
                            </div>
                        </mercur-item>
                    </div>
                </mercur-dialog>
                <mercur-dialog :is-open.sync="editSupplierComplaintCostsDialog">
                    <div slot="header">
                        <h3>Update complaint costs</h3>
                    </div>
                    <div>
                        <mercur-input
                        :clearable="false"
                        :disabled="editSupplierComplaintCostsLoading"
                        type="number"
                        step="0.01"
                        v-model="complaint.supplierComplaintCosts.value">
                            <span slot="suffix">{{ complaint.supplierComplaintCosts.currency | asCurrencySign }}</span>
                        </mercur-input>
                    </div>
                    <div slot="footer">
                        <mercur-button class="btn btn-raised btn-yellow" :disabled="editSupplierComplaintCostsLoading" @click="updateSupplierComplaintCosts">Update</mercur-button>
                    </div>
                    <mercur-progress-bar v-if="editSupplierComplaintCostsLoading" indeterminate></mercur-progress-bar>
                </mercur-dialog>
                <mercur-dialog :is-open.sync="editDescriptionDialog">
                    <div slot="header">
                        <h3>Update complaint description</h3>
                    </div>
                    <div>
                        <mercur-textarea
                            :disabled="editDescriptionLoading"
                            v-model="complaint.description"
                        ></mercur-textarea>
                    </div>
                    <mercur-progress-bar
                        v-if="editDescriptionLoading"
                        indeterminate
                    ></mercur-progress-bar>
                    <div slot="footer">
                        <mercur-button class="btn btn-raised btn-yellow" :disabled="editDescriptionLoading" @click="updateSupplierComplaintDescription">Update</mercur-button>
                    </div>
                </mercur-dialog>
            </div>
            <div v-else>
                <p>Getting complaint details failed. Please <a @click="getComplaintDetails()">try again</a></p>
            </div>
        </div>
    </div>
    <div v-else>
        <p class="permission-message">Not allowed to see this view</p>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import ComplaintComments from '../../../components/complaints/ComplaintComments'
import { stripProperties } from '../../../components/utils/Utils'

export default {
    name: 'ComplaintDetail',
    components: { ComplaintComments },
    props: {
        complaintSupplierId: {
            required: true,
        },
        complaintId: {
            required: true,
        },
        parentRoute: {
            required: true,
        },
        supplierDetails: {
            default: () => ({
                currency: 'EUR',
            }),
        },
    },
    data () {
        return {
            complaint: null,
            complaintStatus: null,
            loading: false,
            downloadLoading: false,
            showComments: false,
            addCommentValue: null,
            attachmentsDialogActive: false,
            editSupplierComplaintCostsDialog: false,
            editSupplierComplaintCostsLoading: false,
            editDescriptionDialog: false,
            editDescriptionLoading: false,
            supplierDepartments: ['SUPPLIER', 'SUPPLY_CHAIN'],
        }
    },
    computed: {
        orderName () {
            if (!this.complaint) {
                return ''
            }
            return `${this.complaint.orderNumber} - ${this.complaint.orderLineNumber}`
        },
        complaintDetailsTable () {
            if (this.complaint === null) {
                return []
            }

            const costs = this.$store.getters['finance/convertValueObjectTo'](this.complaint.supplierComplaintCosts, this.supplierDetails.currency)
            const defaultTable = {
                'Order Number': `${this.complaint.orderNumber} - ${this.complaint.orderLineNumber}`,
                'Product': this.complaint.productName || '',
                'Complaint Type': this.complaint.complaintType,
                'Costs': this.complaint.supplierComplaintCosts.value ? this.$options.filters.asMoney(costs.value, costs.currency) : '-',
                'Complaint description': this.complaint.description,
                'Status': this.$options.filters.asStatus(this.complaint.complaintStatus),
            }

            if (this.supplierDepartments.includes(this.complaint.complaintDepartment) && !this.isSupplier) {
                defaultTable['Supplier Cost Percentage'] = (this.complaint.supplierComplaintCostPercentage ? this.complaint.supplierComplaintCostPercentage : 0) + '%'
            }

            return defaultTable
        },

        commentSections () {
            if (this.isSupplier) {
                return {
                    supplier: {
                        virtualSupplierComplaintNotes: {
                            recipients: ['VIRTUAL_SUPPLIER', 'SUPPLIER'],
                            author: 'Virtual supplier',
                        },
                        supplierComplaintNotes: {
                            recipients: ['VIRTUAL_SUPPLIER', 'SUPPLIER'],
                            author: 'Supplier',
                        },
                    },
                }
            }
            return {
                supplier: {
                    virtualSupplierComplaintNotes: {
                        recipients: ['VIRTUAL_SUPPLIER', 'SUPPLIER'],
                        author: 'Virtual supplier',
                    },
                    supplierComplaintNotes: {
                        recipients: ['VIRTUAL_SUPPLIER', 'SUPPLIER'],
                        author: 'Supplier',
                    },
                },
                merchant: {
                    virtualSupplierComplaintNotes: {
                        recipients: ['MERCHANT', 'VIRTUAL_SUPPLIER'],
                        author: 'Virtual Supplier',
                    },
                    merchantComplaintNotes: {
                        recipients: ['MERCHANT', 'VIRTUAL_SUPPLIER'],
                        author: 'Merchant',
                    },
                },
            }
        },
    },
    methods: {
        setStatus (newStatus) {
            this.complaint.complaintStatus = newStatus
            this.saveComplaintData({ complaint: this.complaint, method: 'save' })
        },
        sendToMerchant () {
            this.saveComplaintData({ complaint: this.complaint, method: 'send-to-merchant' })
        },
        updateSupplierComplaintCosts () {
            this.editSupplierComplaintCostsLoading = true
            this.saveComplaintData({ complaint: this.complaint, method: 'save' }).then(() => {
                this.editSupplierComplaintCostsDialog = false
            }).finally(() => {
                this.editSupplierComplaintCostsLoading = false
            })
        },
        updateSupplierComplaintDescription () {
            this.editDescriptionLoading = true
            this.saveComplaintData({ complaint: this.complaint, method: 'save' }).then(() => {
                this.editDescriptionDialog = false
            }).finally(() => {
                this.editDescriptionLoading = false
            })
        },
        updateStatus () {
            if (this.complaintStatus === this.complaint.complaintStatus) {
                return
            }
            this.complaint.complaintStatus = this.complaintStatus
            this.saveComplaintData({ complaint: this.complaint, method: 'update-status' })
        },
        downloadAttachment (attachment) {
            if (attachment.hash) {
                const url = CONFIG.API.ROUTES.ORDERS.ORDERLINES.DOWNLOAD.replace('{type}', attachment.type)
                const params = new URLSearchParams({
                    hash: attachment.hash,
                    supplierId: this.complaint.supplierId,
                    orderId: this.complaint.orderId,
                    orderLineId: this.complaint.orderLineId,
                }).toString()

                this.$store.dispatch('tasks/addTask', {
                    title: `Download complaint detail csv`,
                    interval: CONFIG.CHECK_TOKEN_RESPONSE_TIMEOUT,
                    attempts: CONFIG.CHECK_TOKEN_RESPONSE_ATTEMPTS,
                    retryOnFail: true,
                    onComplete: data => {
                        this.$bus.$emit('DOWNLOAD_URL_RESOLVED', data)
                    },
                    onPoll: () => {
                        this.$root.$emit('notification:global', {
                            message: `Download started.`,
                        })
                        return Promise.resolve(`${CONFIG.API.URL}${url}?${params}`)
                    },
                    handleResult: (result) => {
                        if (!result || result.length === 0) {
                            return Promise.resolve(null)
                        }
                        return Promise.resolve(result)
                    },
                })
                return
            }

            const payload = {
                applicationId: this.complaint.applicationId,
                orderId: this.complaint.orderId,
                complaintId: this.complaint.complaintId,
                fileName: attachment.fileName,
            }

            this.downloadLoading = true
            const url = CONFIG.API.ROUTES.SUPPLIERS.COMPLAINTS.ATTACHMENTS.DOWNLOAD.replace('{supplierId}', this.complaintSupplierId).replace('{complaintId}', this.complaint.complaintId)
            this.addJob(url)
            this.$store.dispatch('tasks/addTask', {
                title: `Download complaint detail csv`,
                interval: CONFIG.CHECK_TOKEN_RESPONSE_TIMEOUT,
                attempts: CONFIG.CHECK_TOKEN_RESPONSE_ATTEMPTS,
                retryOnFail: true,
                onComplete: data => {
                    this.$bus.$emit('DOWNLOAD_URL_RESOLVED', data.data.url)
                },
                onPoll: () => {
                    this.$root.$emit('notification:global', {
                        message: `Download started.`,
                    })
                    return this.$api.post(url, payload).catch(data => {
                        this.$root.$emit('notification:global', {
                            message: `Downloading failed. Please try again.`,
                            type: 'error',
                            errors: data,
                        })
                    }).finally(() => {
                        this.downloadPending = false
                        this.finishJob(url)
                    })
                },
                handleResult: (result) => {
                    if (result.data.length === 0) {
                        return Promise.resolve(null)
                    }
                    return Promise.resolve(result.data)
                },
            })
        },
        getComplaintDetails (complaintId = null, complaintSupplierId = null) {
            if (complaintId === null) {
                complaintId = this.complaintId
            }
            if (complaintSupplierId === null) {
                complaintSupplierId = this.complaintSupplierId
            }
            const jobName = complaintId
            this.addJob(jobName)
            this.loading = true
            this.complaint = null
            this.attachmentsDialogActive = false
            const url = CONFIG.API.ROUTES.SUPPLIERS.COMPLAINTS.GET.replace('{supplierId}', complaintSupplierId).replace('{complaintId}', complaintId)

            this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                if (!data.data) {
                    throw new Error('Getting the complaint details failed')
                }
                if (Object.keys(data.data.supplierComplaintCosts).length === 0) {
                    data.data.supplierComplaintCosts = data.data.complaintCosts
                }
                this.complaint = {
                    ...data.data,
                    supplierId: complaintSupplierId,
                }
                this.complaintStatus = JSON.parse(JSON.stringify(this.complaint.complaintStatus))
                if (Array.isArray(this.complaint.supplierComplaintCosts)) {
                    this.complaint.supplierComplaintCosts = {
                        value: this.complaint.supplierComplaintCosts[0],
                        currency: this.complaint.supplierComplaintCosts[1],
                    }
                }
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Getting complaint details failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(jobName)
            })
        },

        sendUpdateComplaint (url, complaintData) {
            const payload = stripProperties(complaintData)
            payload.complaintAttachments = payload.complaintAttachments.filter(complaintAttachment => !complaintAttachment.type)

            this.addJob(url)
            return this.$api.post(url, payload).then(({ data }) => {
                this.$set(this, 'complaint', data.data)
                this.$set(this.complaint, 'supplierId', this.complaintSupplierId)

                this.$root.$emit('notification:global', {
                    message: `Complaint saved.`,
                })

                this.$bus.$emit('refreshComplaintTable')
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Saving complaint failed`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.finishJob(url)
            })
        },

        async saveComplaintData (payload) {
            let url
            switch (payload.method) {
            case 'save':
                url = CONFIG.API.ROUTES.SUPPLIERS.COMPLAINTS.UPDATE
                    .replace('{supplierId}', payload.complaint.supplierId || this.supplierId)
                    .replace('{complaintId}', payload.complaint.complaintId)
                break
            case 'send-to-merchant':
                url = CONFIG.API.ROUTES.SUPPLIERS.COMPLAINTS.SEND_TO_MERCHANT
                break
            case 'update-status':
                url = CONFIG.API.ROUTES.SUPPLIERS.COMPLAINTS.UPDATE_STATUS
                break
            }

            if (payload.method === 'save-and-send-to-merchant') {
                url = CONFIG.API.ROUTES.SUPPLIERS.COMPLAINTS.UPDATE
                    .replace('{supplierId}', payload.complaint.supplierId || this.supplierId)
                    .replace('{complaintId}', payload.complaint.complaintId)
                await this.sendUpdateComplaint(url, payload.complaint)

                url = CONFIG.API.ROUTES.SUPPLIERS.COMPLAINTS.SEND_TO_MERCHANT
            }
            return this.sendUpdateComplaint(url, payload.complaint)
        },
    },
    created () {
        this.getComplaintDetails()
    },
    beforeRouteUpdate (to, from, next) {
        next()
        this.getComplaintDetails(to.params.complaintId, to.params.complaintSupplierId)
    },
}
</script>

<style scoped lang="scss">

    .sidebar-detail {
        padding-bottom: 0;
    }
    .complaint-details {
        flex: 0 1 70%;
        font-size: 13px;
        line-height: 1.3em;
        /deep/ dd {
            margin-bottom: 2px;
        }
    }
    .complaint-comments {
        flex: 0 1 100%;
        margin-left: -10px;
    }
</style>
